import { useMemo, useState } from 'react';
//
import { useAuth0 } from '@auth0/auth0-react';
import { Button, Typography } from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
//
import { USER_TYPES } from '../../constants.ts';
import { AuthSideInfo } from './components/AuthSideInfo.tsx';
import CoffeeShopSRC from 'Assets/images/rafiki.svg';
import HomegrownSRC from 'Assets/homegrown.svg';
import { AuthToggleButtonGroup } from './components/AuthToggleButtonGroup.tsx';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { getDesignTokens } from '../../Components/context/muiTheme.tsx';
import CssBaseline from '@mui/material/CssBaseline';
import { onboardingPath } from '../investor-app/constants.ts';
import { businessOnboardingPath } from '../business-app/constants.ts';
import { DJANGO_API } from '../../app-constants.ts';

export const UserTypeSelect = () => {
    const { getAccessTokenSilently } = useAuth0();
    const [userType, setUserType] = useState<USER_TYPES | null>(null);
    const [error, setError] = useState<string | null>(null);
    const navigate = useNavigate();

    const lightTheme = useMemo(() => createTheme(getDesignTokens('light')), []);

    const handleUserTypeChange = (newUserType: USER_TYPES) => {
        setError(null);
        setUserType(newUserType);
    };

    const handleUserMetadataUpdate = async () => {
        if (!userType) {
            setError('Please choose a user type to continue with Homegrown.');
            return;
        }

        const token = await getAccessTokenSilently();
        try {
            await axios.post(
                `${DJANGO_API}/set_own_user_type`,
                { type: userType },
                {
                    headers: {
                        authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                }
            );
            await getAccessTokenSilently({ cacheMode: 'off' });
            if (userType === USER_TYPES.investor) {
                navigate(onboardingPath);
            } else if (userType === USER_TYPES.business_owner) {
                navigate(businessOnboardingPath);
            }
        } catch (error) {
            console.error('Error updating user type:', error);
        }
    };

    return (
        <ThemeProvider theme={lightTheme}>
            <CssBaseline />
            <div className="min-h-full w-full flex">
                <AuthSideInfo />
                <div className="w-[560px] max-w-full mx-auto p-size-3 flex flex-col justify-center gap-size-4">
                    <div className="flex flex-col items-center gap-size-3">
                        <img
                            src={CoffeeShopSRC}
                            alt="Coffee Shop"
                            className="w-[325px] desktop:w-[280px] max-w-full h-auto"
                        />
                        <img
                            src={HomegrownSRC}
                            alt="Homegrown"
                            className="w-[235px] desktop:w-[350px] max-w-full h-auto"
                        />
                    </div>
                    <Typography
                        variant="manrope_20"
                        sx={{
                            fontWeight: 'bold',
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        Choose an account option below
                    </Typography>
                    <AuthToggleButtonGroup
                        userType={userType}
                        handleUserTypeChange={handleUserTypeChange}
                        USER_TYPES={USER_TYPES}
                    />
                    {error && (
                        <span className="text-danger font-medium text-sm">
                            {error}
                        </span>
                    )}
                    <Button
                        fullWidth
                        variant="contained"
                        onClick={handleUserMetadataUpdate}
                    >
                        Continue
                    </Button>
                </div>
            </div>
        </ThemeProvider>
    );
};
