import Auth0 from 'auth0-js';
import { AUTH_CONFIG } from './index.ts';
import { clientAppRoot } from '../../constants.ts';
import { getAbsoluteRouteURL } from '../../Helpers/utils.ts';

const webAuth = new Auth0.WebAuth({
    domain: AUTH_CONFIG.domain,
    clientID: AUTH_CONFIG.clientId,
    audience: AUTH_CONFIG.audience,
    scope: AUTH_CONFIG.scope,
});

export function signUp<
    Data extends { email: string; password: string; user_metadata: object },
>(userData: Data) {
    return new Promise((resolve, reject) =>
        webAuth.signup(
            {
                connection: 'Username-Password-Authentication',
                email: userData.email,
                password: userData.password,
                userMetadata: userData.user_metadata,
            },
            (error, result) => {
                console.log('Auth0 signUp', { error, result });
                if (error) reject(error);
                else resolve(result);
            }
        )
    );
}

export function signUpGoogle() {
    webAuth.authorize({
        redirectUri: getAbsoluteRouteURL(clientAppRoot),
        connection: 'google-oauth2',
        responseType: 'code',
        scope: '',
    });
}

export function signIn<Data extends { email: string; password: string }>(
    { email, password }: Data,
    redirectUri: string,
    state?: object
) {
    return new Promise((resolve, reject) =>
        webAuth.login(
            {
                email,
                password,
                realm: 'Username-Password-Authentication',
                responseType: 'code',
                redirectUri,
                state: state && btoa(JSON.stringify(state)),
            },
            (error, result) => {
                if (result) resolve(result);
                else if (error && error.code !== 'request_error') reject(error);
                else
                    reject({
                        code: error?.code || 'request_failed',
                        description: 'Request failed',
                    });
            }
        )
    );
}

export function getAuth0User(token: string) {
    return new Promise((resolve, reject) => {
        webAuth.client.userInfo(token, (error, auth0user) => {
            if (error) reject(error);
            else resolve(auth0user);
        });
    });
}

export function changePassword(email: string) {
    return new Promise((resolve, reject) =>
        webAuth.changePassword(
            {
                connection: 'Username-Password-Authentication',
                email: email,
            },
            function (err, resp) {
                if (err) {
                    reject(Object(err).message);
                } else {
                    resolve(resp);
                }
            }
        )
    );
}
