export const FILE_STORAGE_ENDPOINT = import.meta.env
    .VITE_APP_FILE_STORAGE_ENDPOINT;
export const GRAPHQL_ENDPOINT = import.meta.env.VITE_APP_GRAPHQL_HTTP_ENDPOINT;
export const GRAPHQL_WS_ENDPOINT = import.meta.env.VITE_APP_GRAPHQL_WS_ENDPOINT;
export const DJANGO_API = import.meta.env.VITE_APP_DJANGO_API_ENTRYPOINT;

export const PLAID_ENTRYPOINT = DJANGO_API + '/plaid';
export const INVESTOR_TREASURY_PRIME_ENTRYPOINT = DJANGO_API + '/treasuryprime';
export const BUSINESS_TREASURY_PRIME_ENTRYPOINT =
    DJANGO_API + '/treasuryprime' + '/business';
export const PHONE_VERIFICATION_ENTRYPOINT = DJANGO_API + '/phone';
export const EMPLOYEE_UPDATE_ENTRYPOINT = DJANGO_API + '/employee';
export const INVESTOR_ENTRYPOINT = DJANGO_API + '/investor';
export const INVESTOR_SECONDARY = DJANGO_API + '/secondary_investor';
export const BUSINESS_ENTRYPOINT = DJANGO_API + '/business';
export const SIGNWELL_ENTRYPOINT = DJANGO_API + '/signwell';
export const EMAILS_ENTRYPOINT = DJANGO_API + '/email';
export const REFERRALS_ENTRYPOINT = DJANGO_API + '/referral';
export const DEAL_ENTRYPOINT = DJANGO_API + '/deal';
export const POS_ENTRYPOINT = DJANGO_API + '/pos';
export const TEST_POS_ENTRYPOINT = DJANGO_API + '/test';

export const baseURL = new URL(import.meta.env.BASE_URL, window.origin);
export const buildInfoURL = new URL('/build-info.json', baseURL);

export const ENABLE_BUSINESS_OWNER_SIGN_UP = [
    'True',
    'true',
    '1',
    'on',
].includes(import.meta.env.VITE_APP_ENABLE_BUSINESS_OWNER_SIGN_UP);
