import {
    FC,
    PropsWithChildren,
    useEffect,
    useState,
    useMemo,
    MouseEventHandler,
} from 'react';
import { useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import {
    TextField,
    Checkbox,
    Button,
    InputAdornment,
    IconButton,
    Typography,
    Link,
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import CoffeeShopSRC from 'Assets/images/rafiki.svg';
import HomegrownSRC from 'Assets/homegrown.svg';
import { ReactComponent as SVGGoogle } from 'Assets/svg/social/google.svg';
import { clientAppRoot, USER_TYPES } from 'constants.ts';
import {
    getAbsoluteRouteURL,
    returnToHandler,
    validateEmail,
} from 'Helpers/utils.ts';
import {
    changePassword,
    signIn,
    signUp,
    signUpGoogle,
} from 'Services/auth/auth0.ts';
import { useShowToast } from 'Helpers/hooks/useShowToast.tsx';
import { checkboxClasses } from '@mui/material/Checkbox';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { AuthSideInfo } from './components/AuthSideInfo.tsx';
import { AuthToggleButtonGroup } from './components/AuthToggleButtonGroup.tsx';
import { getDesignTokens } from 'Components/context/muiTheme.tsx';

enum authType {
    signIn,
    signUp,
    resetPswd,
}

type FormData = {
    email: string;
    password: string;
    user_type: USER_TYPES | null;
};
type Auth0Error = {
    code: string;
    description: any; //eslint-disable-line  @typescript-eslint/no-explicit-any
    policy?: string;
    original?: Error;
};
type AuthProps = {
    returnTo?: string;
};

export const Auth: FC<AuthProps> = ({ returnTo }) => {
    const { state } = useLocation();
    const [type, setType] = useState(authType.signIn);
    const [error, setError] = useState<{
        general?: Auth0Error;
        username?: Auth0Error;
        password?: Auth0Error;
    }>({
        general: state?.errorMsg
            ? { code: 'error', description: state.errorMsg }
            : undefined,
    });
    const [showPassword, setShowPassword] = useState(false);
    const [userType, setUserType] = useState<USER_TYPES | null>(null);

    const {
        register,
        handleSubmit,
        formState: { isSubmitting, errors },
        setValue,
    } = useForm<FormData>({
        defaultValues: {
            email: '',
            password: '',
            user_type: userType,
        },
    });

    useEffect(() => {
        setValue('user_type', userType);
    }, [userType, setValue]);

    const { showSuccess, showError } = useShowToast();
    const formSubmit = async (data: FormData) => {
        setError({});

        if (type === authType.signUp) {
            if (!userType) {
                setError({
                    general: {
                        code: 'no_user_type',
                        description:
                            'Please choose a user type to continue with Homegrown.',
                    },
                });
                return;
            }

            await signUp({
                email: data.email,
                password: data.password,
                user_metadata: { user_type: userType },
            })
                .then(() => signIn(data, getAbsoluteRouteURL(clientAppRoot)))
                .catch((error) => {
                    if (error.code === 'invalid_password') {
                        setError({ password: error });
                    } else if (error.code === 'invalid_signup') {
                        setError({ username: error });
                    } else {
                        setError({ general: error });
                    }
                });
        }

        if (type === authType.signIn) {
            if (!data.password) {
                showError('Please enter your password to sign in.');
                return;
            }

            await signIn(data, getAbsoluteRouteURL(clientAppRoot), { returnTo })
                .then(() => setError({}))
                .catch((error) => setError({ general: error }));
        }

        if (type === authType.resetPswd) {
            await changePassword(data.email)
                .then(() => {
                    showSuccess(
                        'Successfully requested. Check your emails.',
                        'Password Change request'
                    );
                    changeViewType(authType.signIn);
                })
                .then(() => {
                    setError({});
                })
                .catch((error) => setError({ general: error }));
        }
    };

    useEffect(() => {
        returnToHandler.setReturnTo(returnTo);
    }, [returnTo]);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword: MouseEventHandler = (event) =>
        event.preventDefault();

    const handleUserTypeChange = (newUserType: USER_TYPES) => {
        setError({});
        setUserType(newUserType);
    };

    const changeViewType = (type: authType) => {
        setError({});
        setType(type);
    };

    const lightTheme = useMemo(() => createTheme(getDesignTokens('light')), []);

    return (
        <ThemeProvider theme={lightTheme}>
            <CssBaseline />
            <div className="min-h-full w-full flex">
                <AuthSideInfo />
                <div className="w-[560px] max-w-full mx-auto p-size-3 flex flex-col justify-center gap-size-4">
                    <div className="flex flex-col items-center gap-size-3">
                        <img
                            src={CoffeeShopSRC}
                            alt="Coffee Shop"
                            className="w-[325px] desktop:w-[280px] max-w-full h-auto"
                        />
                        <img
                            src={HomegrownSRC}
                            alt="Homegrown"
                            className="w-[235px] desktop:w-[350px] max-w-full h-auto"
                        />
                    </div>
                    <form
                        id="auth-form"
                        onSubmit={handleSubmit(formSubmit)}
                        className=""
                    >
                        {type === authType.signUp && (
                            <fieldset
                                disabled={isSubmitting}
                                className="mb-size-3 font-Manrope font-normal flex flex-wrap justify-around"
                            >
                                <Typography
                                    variant="manrope_14"
                                    sx={{
                                        fontWeight: 'bold',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        marginBottom: '8px',
                                    }}
                                >
                                    Choose an account option below
                                </Typography>
                                <AuthToggleButtonGroup
                                    userType={userType}
                                    handleUserTypeChange={handleUserTypeChange}
                                    USER_TYPES={USER_TYPES}
                                />
                            </fieldset>
                        )}
                        <fieldset
                            disabled={isSubmitting}
                            className="flex flex-col gap-size-1"
                        >
                            <TextField
                                InputProps={{
                                    className:
                                        '[&&]:rounded-raduis-md [&&]:font-Manrope ',
                                    disableUnderline: true,
                                }}
                                InputLabelProps={{
                                    className:
                                        '[&&]:font-Manrope [&&]:text-text-secondary [&&.Mui-focused]:text-text-secondary',
                                }}
                                fullWidth
                                label="Email address"
                                variant="filled"
                                size="medium"
                                type="email"
                                id="email"
                                autoComplete="email"
                                spellCheck="false"
                                autoFocus
                                error={!!errors.email}
                                helperText={errors.email?.message}
                                {...register('email', {
                                    validate: validateEmail,
                                })}
                            />
                            {type !== authType.resetPswd && (
                                <>
                                    <TextField
                                        InputProps={{
                                            className:
                                                '[&&]:rounded-raduis-md [&&]:font-Manrope ',
                                            disableUnderline: true,
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={
                                                            handleClickShowPassword
                                                        }
                                                        onMouseDown={
                                                            handleMouseDownPassword
                                                        }
                                                        edge="end"
                                                    >
                                                        {showPassword ? (
                                                            <VisibilityOffIcon color="primary" />
                                                        ) : (
                                                            <VisibilityIcon color="primary" />
                                                        )}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                        InputLabelProps={{
                                            className:
                                                '[&&]:font-Manrope [&&]:text-text-secondary [&&.Mui-focused]:text-text-secondary',
                                        }}
                                        fullWidth
                                        label="Password"
                                        variant="filled"
                                        size="medium"
                                        type={
                                            showPassword ? 'text' : 'password'
                                        }
                                        spellCheck="false"
                                        autoComplete="password"
                                        id="password"
                                        {...register('password')}
                                    />
                                    {error.password?.code ===
                                        'invalid_password' && (
                                        <ErrorMsg>
                                            Password is too weak
                                        </ErrorMsg>
                                    )}
                                    <PasswordPolicy
                                        policyString={error.password?.policy}
                                        rules={
                                            error.password?.description?.rules
                                        }
                                    />
                                </>
                            )}

                            {type === authType.signUp && (
                                <div className="flex flex-row items-center">
                                    <Checkbox
                                        disableRipple={true}
                                        required
                                        sx={{
                                            padding: '0',
                                            marginRight: '7px',
                                            alignItems: 'flex-start',
                                            [`&, &.${checkboxClasses.checked}`]:
                                                {
                                                    color: '#000000',
                                                },
                                        }}
                                    />
                                    <Typography
                                        variant="custom_caption_manrope"
                                        component="div"
                                    >
                                        By selecting Continue, I agree to
                                        Homegrown’s{' '}
                                        <Link
                                            href="https://www.joinhomegrown.com/terms-of-use"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            sx={{
                                                fontWeight: 700,
                                                textDecoration: 'underline',
                                                color: 'inherit',
                                            }}
                                        >
                                            Terms of Use and Privacy Policy
                                        </Link>
                                        .
                                    </Typography>
                                </div>
                            )}
                        </fieldset>
                        <fieldset
                            disabled={isSubmitting}
                            className="mt-size-3 flex flex-col gap-size-2"
                        >
                            <Button
                                fullWidth
                                variant="contained"
                                size="large"
                                color="inherit"
                                form="auth-form"
                                type="submit"
                                disabled={isSubmitting}
                                className="[&&]:px-size-2 [&&]:py-size-1 [&&]:rounded-raduis-md [&&]:font-Manrope"
                                sx={{
                                    backgroundColor: '#000000',
                                    color: '#FFFFFF',
                                    textTransform: 'none',
                                    '&:hover': {
                                        backgroundColor: '#000000',
                                    },
                                }}
                            >
                                {type === authType.signIn && 'Sign In'}
                                {type === authType.signUp && 'Continue'}
                                {type === authType.resetPswd &&
                                    'Reset Password'}
                            </Button>
                            {error.general && (
                                <ErrorMsg>{error.general.description}</ErrorMsg>
                            )}
                            {error.username?.code === 'invalid_signup' && (
                                <ErrorMsg>
                                    Looks like your account already exists.
                                    Please use your email to log in to your
                                    account.
                                </ErrorMsg>
                            )}
                            {(type === authType.signIn ||
                                type === authType.signUp) && (
                                <>
                                    <div className="flex items-center gap-2 h-10.5">
                                        <hr className="border-t flex-grow" />
                                        <span className="font-Manrope font-normal text-xs">
                                            OR
                                        </span>
                                        <hr className="border-t flex-grow" />
                                    </div>
                                    <div>
                                        <Button
                                            fullWidth
                                            variant="outlined"
                                            color="inherit"
                                            size="large"
                                            className="[&&]:px-size-2 [&&]:py-size-1 [&&]:rounded-raduis-md [&&]:font-Manrope"
                                            sx={{
                                                textTransform: 'none',
                                            }}
                                            startIcon={
                                                <SVGGoogle
                                                    width="24px"
                                                    height="25px"
                                                />
                                            }
                                            onClick={signUpGoogle}
                                        >
                                            <div className="w-full">
                                                {type === authType.signIn &&
                                                    'Sign In With Google'}
                                                {type === authType.signUp &&
                                                    'Continue With Google'}
                                            </div>
                                        </Button>
                                    </div>
                                </>
                            )}
                        </fieldset>
                    </form>
                    <div className="flex flex-col items-center gap-size-4">
                        {(type === authType.signUp ||
                            type === authType.resetPswd) && (
                            <Typography variant="manrope_14">
                                Already have an account?{' '}
                                <Typography
                                    variant="manrope_14"
                                    component="span"
                                    role="button"
                                    sx={{
                                        fontWeight: 700,
                                        textDecoration: 'underline',
                                    }}
                                    onClick={() =>
                                        changeViewType(authType.signIn)
                                    }
                                >
                                    Sign In
                                </Typography>
                            </Typography>
                        )}
                        {(type === authType.signIn ||
                            type === authType.resetPswd) && (
                            <Typography variant="manrope_14">
                                Don't have an account?{' '}
                                <Typography
                                    variant="manrope_14"
                                    component="span"
                                    role="button"
                                    sx={{
                                        fontWeight: 700,
                                        textDecoration: 'underline',
                                    }}
                                    onClick={() =>
                                        changeViewType(authType.signUp)
                                    }
                                >
                                    Sign Up
                                </Typography>
                            </Typography>
                        )}
                        {type === authType.signIn && (
                            <Typography
                                variant="manrope_14"
                                component="span"
                                role="button"
                                sx={{
                                    fontWeight: 700,
                                    textDecoration: 'underline',
                                }}
                                onClick={() =>
                                    changeViewType(authType.resetPswd)
                                }
                            >
                                Forgot your password?
                            </Typography>
                        )}
                    </div>
                </div>
            </div>
        </ThemeProvider>
    );
};
export default Auth;

const ErrorMsg: FC<PropsWithChildren> = ({ children }) => (
    <span className=" text-danger font-Manrope font-medium text-sm">
        {children}
    </span>
);

const PasswordPolicy: FC<{ policyString?: string; rules: PolicyRule[] }> = ({
    policyString,
    rules,
}) => {
    if (!policyString || !Array.isArray(rules)) return null;

    const parseRules = rules.map((rule) => {
        const items = rule.items?.map((item) => (
            <li className={`list-disc ${item.verified ? 'text-success' : ''}`}>
                {format(item)}
            </li>
        ));

        return (
            <li className={`${rule.verified ? 'text-success' : ''}`}>
                - {format(rule)}
                {items && <ul className="pl-6">{items}</ul>}
            </li>
        );
    });
    return (
        <div className="shadow-shadow rounded-xl px-2 desktop:px-size-3 py-2">
            <div className="text-sm font-semibold text-[#979797] my-1.5">
                Your password must contain
            </div>
            <div className="my-2.5 leading-[187.5%] text-sm font-medium">
                <ul>{parseRules}</ul>
            </div>
        </div>
    );
};

function format<T extends { message: string; format?: (string | number)[] }>({
    message,
    format,
}: T) {
    if (!format) return message;
    let i = 0;
    return message.replace(/%d/g, () => String(format[i++]));
}
